<template>

<app-wrapper>

	<com-filter v-on:change="onFilterChange" />
	<com-feed :items="feed" :loading="loading" />
	<com-paginate v-if="total > feed.length" v-on:click="onPaginate" :loading="loading" />

</app-wrapper>

</template>

<script>

import Vue from 'vue'

export default {

	components: {

		'com-filter': () => import('./news/Filter'),
		'com-feed': () => import('./news/Feed'),
		'com-paginate': () => import('./news/Paginate')

	},

	data: function() {

		return {
			loading: true,
			feed: [],
			filter: {
				events: false,
				opinions: false,
				search: ''
			},
			page: 1,
			total: 0
		}

	},

	created: function() {

		if (!this.$route.query.topic) this.load()

	},

	methods: {

		onPaginate: function() {

			this.page++

			this.load()

		},

		onFilterChange: function(filter) {

			Vue.set(this, 'filter', filter)

			this.page = 1
			this.feed = []
			this.total = 0

			this.load()

		},

		load: function() {

			this.loading = true

			this.$api.get('feed', {
				events: this.filter.events ? 1 :0,
				opinions: this.filter.opinions ? 1 : 0,
				search: this.filter.search,
				page: this.page
			}).then(function(json) {

				this.feed = json.feed
				this.total = json.total

				this.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>

<template>

<app-wrapper class="generic">

	<app-wrapper :content="true" :text="true" class="generic-content" v-html="$state.page.content" />

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.generic {
	padding: 64px 0px;
}

.is-tablet .generic {
	padding: 40px 0px;
}

.is-mobile .generic {
	padding: 30px 0px;
}

</style>

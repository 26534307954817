<template>

<app-wrapper>

	<com-banner />
	<com-form />

</app-wrapper>

</template>

<script>

export default {

	components: {

		'com-banner': () => import('./contact/Banner'),
		'com-form': () => import('./contact/Form')

	}

}

</script>

<style scoped>

</style>

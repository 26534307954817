<template>

<app-wrapper>

	<com-banner />
	<com-news />
	<com-about />
	<com-quotes />
	<com-logos />

</app-wrapper>

</template>

<script>

export default {

	components: {

		'com-banner': () => import('./home/Banner'),
		'com-news': () => import('./home/News'),
		'com-about': () => import('./home/About'),
		'com-quotes': () => import('./home/Quotes'),
		'com-logos': () => import('./home/Logos')

	}

}

</script>

<style scoped>

</style>

import Vue from 'vue'
import Router from 'vue-router'

import pageHome from '@/components/page/Home'
import pageAbout from '@/components/page/About'
import pageServices from '@/components/page/Services'
import pageService from '@/components/page/Service'
import pageClients from '@/components/page/Clients'
import pageNews from '@/components/page/News'
import pageArticle from '@/components/page/Article'
import pageContact from '@/components/page/Contact'
import pageGeneric from '@/components/page/Generic'

Vue.use(Router)

export default new Router({
	mode: 'history',
	base: process.env.VUE_APP_BASE,
	routes: [
		{ name: 'Home', path: '/', meta: { }, component: pageHome },
		{ name: 'About', path: '/about', meta: { }, component: pageAbout },
		{ name: 'Services', path: '/services', meta: { }, component: pageServices },
		{ name: 'Service', path: '/services/:service', meta: { }, component: pageService },
		{ name: 'Clients', path: '/clients', meta: { }, component: pageClients },
		{ name: 'News', path: '/events-and-opinions', meta: { }, component: pageNews },
		{ name: 'Article', path: '/events-and-opinions/:article', meta: { }, component: pageArticle },
		{ name: 'Contact', path: '/contact', meta: { }, component: pageContact },
		{ name: 'Generic', path: '/:page', meta: { }, component: pageGeneric },
		{ name: 'Page', path: '*', meta: { }, component: pageGeneric }
	]
})
<template>

<app-wrapper>

	<com-content />

</app-wrapper>

</template>

<script>

export default {

	components: {

		'com-content': () => import('./service/Content')

	}

}

</script>

<style scoped>

</style>

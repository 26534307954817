<template>

<app-wrapper>

	<com-content />
	<com-history />
	<com-logos />

</app-wrapper>

</template>

<script>

export default {

	components: {

		'com-content': () => import('./about/Content'),
		'com-history': () => import('./about/History'),
		'com-logos': () => import('./home/Logos')

	}

}

</script>

<style scoped>

</style>

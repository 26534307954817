<template>

<div class="wrapper" :class="{'is-content': content, 'is-text': text, 'is-center': center}">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['content', 'text', 'center']

}

</script>

<style scoped>

.wrapper {
	width: 100%;
}

.wrapper >>> h2 {
	font-size: 48px;
	line-height: 64px;
	text-align: center;
	font-weight: 300;
	color: #112A45;
	margin-bottom: 40px;
}

.wrapper.is-content {
	max-width: 1280px;
	margin: 0px auto;
}

.is-tablet .wrapper.is-content {
	max-width: 728px;
}

.is-mobile .wrapper.is-content {
	width: 100%;
	padding: 0px 10px;
}

.is-tablet .wrapper >>> h2 {
	font-size: 32px;
	line-height: 36px;
}

.is-mobile .wrapper >>> h2 {
	font-size: 24px;
	line-height: 30px;
	margin-bottom: 30px;
}

.wrapper.is-text >>> h2 {
	text-align: left;
}

.wrapper.is-center.is-text >>> h2 {
	text-align: center;
}

.wrapper.is-text >>> h3 {
	font-size: 24px;
	line-height: 32px;
	font-weight: 400;
	color: #112A45;
	margin-bottom: 30px;
}

.wrapper.is-text >>> h4 {
	font-size: 18px;
	line-height: 32px;
	font-weight: 500;
	color: #112A45;
	margin-bottom: 20px;
}

.wrapper.is-text >>> p {
	font-size: 16px;
	line-height: 24px;
	color: #585A5D;
	font-weight: 400;
	margin-bottom: 20px;
}

.is-mobile .wrapper.is-text >>> p {
	font-size: 14px;
	line-height: 24px;
}

.wrapper.is-center.is-text >>> p {
	text-align: center;
}

.wrapper.is-text >>> li:before {
	content: '';
	position: absolute;
	left: 0px;
	top: 8px;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background-color: #BBD9CD;
}

.wrapper.is-text >>> li {
	padding-left: 20px;
	font-size: 16px;
	color: #585A5D;
	line-height: 24px;
	margin-bottom: 20px;
}

.is-mobile .wrapper.is-text >>> li {
	font-size: 14px;
}

.wrapper.is-text > *:last-child {
	margin-bottom: 0px;
}

.wrapper.is-text >>> blockquote {
	padding: 20px;
	border: 1px solid #BCD9CD;
	border-left-width: 10px;
	border-radius: 5px;
	margin-bottom: 20px;
	background-color: #fff;
	box-sizing: border-box;
}

.wrapper.is-text >>> blockquote > *:last-child {
	margin-bottom: 0px;
}

.wrapper.is-text >>> img {
	max-width: 100%;
}

.wrapper.is-text >>> i,
.wrapper.is-text >>> em {
	font-style: italic;
}

.wrapper.is-text >>> a {
	color: #BBD9CD;
	font-weight: 500;
}

.is-desktop .wrapper.is-text >>> a:hover {
	text-decoration: underline;
}

</style>

<template>

<app-wrapper class="article">

	<app-wrapper :content="true" class="article-content">

		<com-content />
		<com-side />

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

	components: {

		'com-content': () => import('./article/Content'),
		'com-side': () => import('./article/Side')

	}

}

</script>

<style scoped>

.article-content {
	display: flex;
}

.is-tablet .article-content,
.is-mobile .article-content {
	flex-direction: column;
}

</style>

<template>

<app-wrapper>

	<com-banner />
	<com-services />

</app-wrapper>

</template>

<script>

export default {

	components: {

		'com-banner': () => import('./services/Banner'),
		'com-services': () => import('./services/Services')

	}

}

</script>

<style scoped>

</style>

<template>

<app-wrapper>

	<com-banner />
	<com-clients />

</app-wrapper>

</template>

<script>

export default {

	components: {

		'com-banner': () => import('./clients/Banner'),
		'com-clients': () => import('./clients/Clients')

	},

	data: function() {

		return {}

	}

}

</script>

<style scoped>

</style>

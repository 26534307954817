<template>

<router-link :to="{name: 'Article', params: { article: item.slug }}" class="card" :class="{'is-event': item.event}">

	<div class="card-image" v-bgimage="item.image">
	
		<div class="card-image-label" v-if="item.event">Event</div>
	
	</div>

	<div class="card-text">
	
		<h3>{{ item.name }}</h3>

		<div class="card-text-date" v-if="!item.event"><i class="fa-regular fa-calendar"></i>{{ item.date | formatDate('Do MMMM YYYY') }}</div>

		<p v-html="item.description"></p>
	
	</div>

	<div class="card-tags" v-if="item.tags.length">

		<span v-for="(tag, index) in item.tags" :key="index">{{ tag }}</span>
	
	</div>

</router-link>

</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

.card {
	border: 1px solid #BBD9CD;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
}

.card-image {
	height: 205px;
	background-color: #eee;
	background-position: 50% 50%;
	background-size: cover;
}

.card-image-label {
	background-color: #BBD9CD;
	color: #112A45;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
	padding: 5px 20px;
	width: 120px;
	position: absolute;
	left: 0px;
	bottom: 20px;
}

.card-text {
	padding: 20px 20px 20px 20px;
	flex-grow: 1;
}

.card.is-event .card-text {
	background-color: #112A45;
}

.card-text h3 {
	color: #112A45;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	margin-bottom: 20px;
}

.card.is-event .card-text h3 {
	color: #fff;
}

.card-text-date {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	font-size: 12px;
	color: #585A5D;
	font-weight: 400;
}

.card-text-date i {
	color: #BBD9CD;
	margin-right: 10px;
	font-size: 20px;
}

.card-text p {
	color: #585A5D;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

.card.is-event .card-text p {
	color: #BBD9CD;
}

.card-tags {
	background-color: #BBD9CD;
	padding: 10px 20px 10px 50px;
	background-image: url(~@/assets/tags.png);
	background-position: 20px 50%;
	background-repeat: no-repeat;
	background-size: auto 14px;
}

.card-tags span {
	line-height: 12px;
	display: inline-block;
	color: #112A45;
	position: relative;
	font-size: 12px;
	font-weight: 400;
	padding: 0px 6px 0px 5px;
}

.card-tags span:after {
	content: '';
	height: 10px;
	width: 1px;
	background-color: #112A45;
	position: absolute;
	right: 0px;
	top: 50%;
	margin-top: -5px;
}

.card-tags span:last-child:after {
	display: none;
}

</style>
